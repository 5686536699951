@font-face {
  font-family: "Allura";
  src: local("Allura-Regular"),
    url("fonts/Allura-Regular.ttf") format("truetype");
  font-weight: bold;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow: hidden;
}

* {
  font-size: 1.5rem;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.title {
  text-shadow: 0 1px #808d93, -1px 0 #000000, -1px 2px #808d93, -2px 1px #000000;
  z-index: 10;
  font-family: "Allura";
}

.subtitle {
  text-shadow: 0 1px #808d93, -1px 0 #000000, -1px 2px #808d93, -2px 1px #000000;
  z-index: 10;
  font-family: "Allura";
  margin: 0;
  margin-top: -50px;
  font-size: 0.8rem;
}

img.maska {
  height: 50px;
  position: absolute;
  z-index: 0;
  transition: all 5s ease;
}

.dan {
  color: #f72f31;
}

.sat {
  color: #42c1be;
}

.minut {
  color: #8ec243;
}

.sekund {
  color: #fec01f;
}

.osansto {
  position: absolute;
  bottom: 0;
  text-align: center;
  width: 100vw;
  left: 0;
  color: white;
  font-size: 0.5rem;
  text-decoration: none;
}
